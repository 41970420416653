// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
};

jQuery(function() {
  if($(".js-replace-img")) {
    replaceSrc();
  }
});

// Responsive menu
jQuery('#toggle').click(function() {
  jQuery(this).toggleClass('active');
  jQuery('#primary_nav').toggleClass('open');
  jQuery('#body').toggleClass('overflow');
});

// Aside nav
jQuery('#btn-aside').click(function() {
  jQuery('#aside').one('transitionend', function() {
    jQuery('#aside').toggleClass('open');
  })
})
